/* You can add global styles to this file, and also import other style files */
@tailwind base;
/* Simple reusable components provided by tailwind */
@tailwind components;
/* utility classes generated based on our tailwind.config.js */
@tailwind utilities;

/* fallback font */
body {
  font-family: 'Inter', sans-serif;
}

html,
body,
#root {
  height: 100%;
  position: fixed;
  overflow: hidden;
  width: 100%;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.react-joyride__spotlight{
  pointer-events:inherit !important
}
div.react-joyride__overlay {pointer-events: none !important;}

@layer utilities {
  .placeholder-fontWeight::placeholder {
    font-weight: 300;
  }
}

/* Scrollbar Styling for Desktop views */

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-auto {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* .pad-children-6 > div {
  padding-bottom: 6rem;
}

.pad-children-1 > div {
  padding-bottom: 1rem;
} */

ion-content {
  --keyboard-offset: 0 !important;
}

.wrapper-with-sticky-button {
  height: calc(100vh - 80px);
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 16px;
}

.bg-bannerx2 {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center;
}

.bg-logo {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center;
}

.mirrored {
  transform: scaleX(-1);
}